/* General Styling */
.home {
  font-family: 'Roboto', sans-serif;
  line-height: 1.8;
  background-color: #f4f4f4; /* Couleur de fond douce */
  color: #333;
  padding: 0;
  margin: 0;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: linear-gradient(135deg, #f9a825, #ffd740); /* Couleurs chaudes et accueillantes */
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}

.hero-text {
  max-width: 600px;
  margin-bottom: 20px;
}

.hero-text h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background: #333;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background: #555;
  transform: translateY(-5px);
}

.hero-image-container {
  max-width: 400px;
}

.hero-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Why Choose Us Section */
.why-us {
  padding: 60px 20px;
  background: #fff;
  text-align: center;
}

.why-us h2 {
  color: #333;
  font-size: 2.2em;
  margin-bottom: 30px;
}

.why-us-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.why-us-item {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-us-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.why-us-item .icon {
  font-size: 2.5em;
  color: #ffd740;
  margin-bottom: 15px;
}

.why-us-item h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #333;
}

.why-us-item p {
  font-size: 1em;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .hero-text h1 {
    font-size: 2em;
  }

  .cta-button {
    font-size: 1em;
    padding: 12px 25px;
  }

  .why-us-list {
    flex-direction: column;
  }

  .why-us-item {
    max-width: 100%;
  }
}
