/* Testimonials Section */
.testimonials {
  padding: 80px 20px;
  background: linear-gradient(135deg, #f4f4f4, #ffffff);
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.testimonials h2 {
  color: #00509e;
  font-size: 2.5em;
  margin-bottom: 40px;
}

.testimonials p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
}

/* Testimonial Container */
.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

/* Testimonial Card */
.testimonial {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 320px;
  padding: 30px;
  font-style: italic;
  transform: translateY(50px);
  opacity: 0;
  transition: transform 0.8s ease, opacity 0.8s ease;
  position: relative;
}

.testimonial.visible {
  transform: translateY(0);
  opacity: 1;
}

.testimonial:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.testimonial h3 {
  color: #003d80;
  margin-top: 20px;
  font-size: 1em;
  text-align: right;
}

.quote-icon {
  font-size: 4em;
  color: #00509e;
  position: absolute;
  top: -30px;
  left: 20px;
  opacity: 0.1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial {
    width: 100%;
  }
}
