/* Back to Top Button */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none; /* Le bouton est caché par défaut */
  background-color: #00509e;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Pour être au-dessus des autres éléments */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.back-to-top:hover {
  background-color: #003f7f;
  transform: scale(1.1); /* Agrandissement du bouton au survol */
}
