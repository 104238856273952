/* General Styling */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #f4f8fc, #ffffff);
}

/* Pricing Section */
.pricing {
  padding: 60px 20px;
  text-align: center;
}

.pricing-header h2 {
  font-size: 2.2em;
  color: #00509e;
  margin-bottom: 10px;
}

.pricing-intro {
  font-size: 1.1em;
  color: #555;
  max-width: 700px;
  margin: 0 auto 30px;
  line-height: 1.8;
}

/* Pricing Cards */
.pricing-table {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.pricing-card {
  background: linear-gradient(135deg, #ffffff, #f4f8fc);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 300px;
  max-width: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.pricing-card.popular {
  border: 2px solid #00509e;
  transform: scale(1.05);
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.pricing-card h3 {
  font-size: 1.6em;
  color: #00509e;
  margin-bottom: 15px;
}

.pricing-card .price {
  font-size: 1.8em;
  color: #333;
  font-weight: bold;
  margin: 20px 0;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
}

.pricing-card ul li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.pricing-card ul li::before {
  content: '✔';
  color: #00509e;
  position: absolute;
  left: 0;
  font-size: 1.2em;
}

.contact-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #00509e;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.contact-link:hover {
  background-color: #003d80;
}

/* Badge Styling */
.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff9800;
  color: #fff;
  font-size: 0.8em;
  padding: 5px 10px;
  border-radius: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

/* Footer */
.pricing-footer {
  margin-top: 40px;
  font-size: 1.1em;
  color: #555;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .pricing-header h2 {
    font-size: 1.8em;
  }

  .pricing-intro {
    font-size: 1em;
  }

  .pricing-card {
    flex: 1 1 100%;
  }

  .pricing-card h3 {
    font-size: 1.4em;
  }

  .pricing-card .price {
    font-size: 1.6em;
  }

  .pricing-card ul {
    font-size: 0.95em;
  }
}
