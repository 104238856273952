/* Services Page */
.services {
  padding: 60px 20px;
  background: linear-gradient(180deg, #ffffff 0%, #eef4fc 100%);
  color: #333;
}

.services-header {
  text-align: center;
  margin-bottom: 40px;
}

.services-header h2 {
  font-size: 2.5em;
  color: #00509e;
  font-weight: bold;
  margin-bottom: 15px;
}

.services-header p {
  font-size: 1.2em;
  color: #555;
  max-width: 800px;
  margin: 0 auto 20px;
  line-height: 1.8;
}

/* Learn More Section */
.learn-more-container {
  text-align: center;
  margin-top: 40px;
}

.learn-more-all {
  display: inline-block;
  color: #00509e;
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #00509e;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.learn-more-all:hover {
  background-color: #00509e;
  color: #fff;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  justify-items: center;
  padding: 0 20px;
}

.service-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.4s ease, box-shadow 0.4s ease, border-color 0.3s ease;
  max-width: 350px;
  position: relative;
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  border-color: #00509e;
}

.service-icon {
  width: 70px;
  margin-bottom: 20px;
  transition: transform 0.4s ease;
}

.service-card:hover .service-icon {
  transform: rotate(15deg) scale(1.1);
}

.service-card h3 {
  color: #00509e;
  font-size: 1.6em;
  margin-bottom: 15px;
}

.service-card p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.8;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-header h2 {
    font-size: 2.2em;
  }

  .services-header p {
    font-size: 1.1em;
  }

  .service-card {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .services-header {
    margin-bottom: 30px;
  }

  .services-header h2 {
    font-size: 2em;
  }

  .service-card {
    max-width: 100%;
  }

  .service-card h3 {
    font-size: 1.4em;
  }

  .service-card p {
    font-size: 1em;
  }

  .learn-more-all {
    font-size: 1em;
    padding: 8px 16px;
  }
}
