.form-section {
  padding: 40px 20px;
  background: #fff;
  border-top: 3px solid #00509e;
  text-align: center;
}

.form-section h2 {
  color: #00509e;
  font-size: 2.2em;
  margin-bottom: 20px;
}

.form-section form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

form label {
  font-size: 1em;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
  text-align: left;
}

form input,
form textarea,
form button {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
form textarea {
  height: 100px; /* يمكنك تعديل القيمة حسب الحاجة */
}

form button {
  background: linear-gradient(135deg, #00509e, #003f7f);
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

form button:hover {
  background: linear-gradient(135deg, #003f7f, #00509e);
  transform: translateY(-3px);
}
