.service-details {
    padding: 60px 20px;
    background: #f9f9f9;
    color: #333;
    font-family: 'Roboto', sans-serif;
    line-height: 1.8;
    text-align: center;
  }
  
  .service-details h2 {
    color: #00509e;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .service-details p {
    font-size: 1.2em;
    margin-bottom: 25px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .service-details ul {
    list-style: none;
    margin: 20px auto;
    padding: 0;
    max-width: 700px;
    text-align: left;
  }
  
  .service-details ul li {
    margin-bottom: 15px;
    font-size: 1.1em;
    padding-left: 30px;
    position: relative;
  }
  
  .service-details ul li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #00509e;
    font-size: 1.5em;
  }
  
  .contact-us-link {
    display: inline-block;
    margin-top: 30px;
    padding: 15px 30px;
    background: linear-gradient(135deg, #00509e, #003d80);
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
    border-radius: 5px;
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .contact-us-link:hover {
    transform: translateY(-5px);
    background: linear-gradient(135deg, #003d80, #00509e);
  }
  