/* General Header Styling */
.header {
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  color: var(--white-color);
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Hidden Header Style */
.header.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

/* Header Visible Style */
.header:not(.hidden) {
  transform: translateY(0);
  opacity: 1;
}

/* Logo Styling */
.logo {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Menu Toggle Button */
.menu-toggle {
  font-size: 1.8rem;
  background: none;
  color: var(--white-color);
  border: none;
  cursor: pointer;
  display: none;
  transition: transform 0.3s ease;
}

.menu-toggle:hover {
  transform: scale(1.1);
}

/* Navbar Styling */
.navbar {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.navbar li {
  margin: 0;
  position: relative;
}

.navbar a {
  color: var(--white-color);
  text-decoration: none;
  font-size: 1.1rem;
  position: relative;
  padding: 10px;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: var(--light-blue);
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar {
    display: none;
    flex-direction: column;
    background-color: var(--secondary-color);
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 15px;
  }

  .navbar.show {
    display: flex;
  }

  .navbar ul {
    flex-direction: column;
    gap: 15px;
  }

  .navbar li {
    text-align: center;
  }
}

/* Overlay Styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.overlay.show {
  display: block;
}
