.privacy-policy {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy h1 {
    color: #00509e;
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy h2 {
    color: #003d80;
    font-size: 1.8em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    color: #333;
    font-size: 1.1em;
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-policy ul li {
    color: #555;
    margin-bottom: 10px;
  }
  
  .privacy-policy strong {
    color: #00509e;
  }
  