/* General Styles */
.footer {
  background: #003366; /* Bleu foncé et moderne */
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

/* Footer Container */
.footer-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1200px;
  margin: auto;
}

.footer-section {
  text-align: left;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #ffd740; /* Couleur contrastée pour les titres */
}

.footer-section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section li {
  margin-bottom: 8px;
}

.footer-section a {
  color: #ffd740;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #ffa726; /* Couleur plus chaude au survol */
}

/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 15px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-bottom p {
  margin: 0;
}

.footer-bottom nav a {
  color: #ffd740;
  margin: 0 10px;
  text-decoration: none;
}

.footer-bottom nav a:hover {
  color: #ffa726;
}

/* Responsive Design */
@media (min-width: 768px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-section {
    width: 30%;
  }

  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
