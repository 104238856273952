/* General Styling */
.portfolio {
  padding: 50px 20px;
  background-color: #f4f4f4; /* Couleur de fond douce */
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.portfolio h2 {
  color: #003d80; /* Bleu professionnel */
  font-size: 2.5em;
  margin-bottom: 20px;
}

.portfolio-intro {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

/* Portfolio Gallery */
.portfolio-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.portfolio-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.portfolio-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.portfolio-item h3 {
  color: #003d80; /* Bleu cohérent avec le titre */
  font-size: 1.4em;
  margin-bottom: 10px;
}

.portfolio-item p {
  font-size: 1em;
  color: #666;
  line-height: 1.5;
}

/* Portfolio Footer */
.portfolio-footer {
  margin-top: 40px;
  font-size: 1.1em;
  color: #555;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

.portfolio-footer strong {
  color: #003d80;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .portfolio-intro {
    font-size: 1em;
    margin-bottom: 30px;
  }

  .portfolio-gallery {
    gap: 20px;
  }

  .portfolio-item {
    width: 100%;
  }

  .portfolio-footer {
    font-size: 1em;
  }
}
