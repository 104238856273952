.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #00509e;
  color: white;
  text-align: center;
  padding: 15px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-banner p {
  margin: 0;
  font-size: 1rem;
}

.cookie-banner a {
  color: #ffd700;
  text-decoration: underline;
}

.cookie-banner button {
  background: #ffd700;
  color: #00509e;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 20px;
  transition: background 0.3s ease;
}

.cookie-banner button:hover {
  background: #e5c100;
}
